<template>
  <div class="card">
    <a-page-header
      style="border: 1px solid rgb(235, 237, 240)"
      :title="classroom.name"
      :sub-title="$t('inscription.nbEleves', { number: classroomCount })"
      class="card-header"
      @back="() => $router.push('/inscriptionScolarite')"
    >
    </a-page-header>
    <div class="card-body">
      <h5 style="color: blue; font-weight: bold">
        {{ $t("inscription.inscription") }}
      </h5>
      <div style="margin-bottom: 20px">
        <a-button @click="showModal" type="user-add"
          ><a-icon type="user-add" />{{
            $t("inscription.nouvInscri")
          }}</a-button
        >
        <a-button-group style="float: right">
          <a-button type="file-pdf" @click="generateFicheClassPDF"
            ><a-icon type="file-pdf" @click="generateFicheClassPDF" />{{
              $t("inscription.ficheParentClasse")
            }}</a-button
          >
          <a-button type="file-pdf" @click="convert"
            ><a-icon type="file-pdf" />{{
              $t("inscription.ficheClasse")
            }}</a-button
          >
        </a-button-group>
      </div>
      <a-table
        @change="tableChanged"
        :pagination="true"
        :data-source="data"
        :columns="columns"
        :loading="tableLoading"
      >
        <template slot="gender" slot-scope="text">
          {{ text == "male" ? $t("profil.garcon") : $t("profil.fille") }}
        </template>
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`${$t('personnel.chercher')} ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
            >{{ $t("recette.chercher") }}</a-button
          >
          <a-button
            size="small"
            style="width: 90px"
            @click="() => handleReset(clearFilters)"
            >{{ $t("action.reinitialiser") }}</a-button
          >
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        />
        <template slot="customRender" slot-scope="text, record, index, column">
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>{{ text }}</template>
        </template>
      </a-table>
      <div>
        <a-modal
          v-model="visibleModal"
          @cancel="handleCancel"
          :title="$t('all.enterInformationBelow')"
          :width="750"
          :height="1200"
          :footer="false"
        >
          <a-form :form="form" class="container" @submit="addInscription">
            <div class="row">
              <div class="col-lg-6">
                <a-form-item :label="$t('inscription.enfant')">
                  <a-select
                    show-search
                    :autoFocus="true"
                    option-filter-prop="children"
                    mode="multiple"
                    :filter-option="filterOption"
                    :placeholder="$t('inscription.enfant')"
                    v-decorator="[
                      'students',
                      {
                        rules: [
                          {
                            required: true,
                            message: $t('requis.selectionEnfant'),
                          },
                        ],
                      },
                    ]"
                  >
                    <a-select-option
                      v-for="eleve in eleves"
                      :key="eleve._id"
                      :value="eleve._id"
                    >
                      {{ eleve.firstName }} {{ eleve.lastName }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </div>
              <div class="cold-lg-6">
                <a-form-item :label="$t('all.startDate')">
                  <a-date-picker
                    format="DD/MM/YYYY"
                    :disabled-date="disabledDate"
                    v-decorator="[
                      'start',
                      {
                        rules: [
                          {
                            required: false,
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </div>
            </div>
            <div class="row"></div>
            <div class="row">
              <div class="col-lg-6">
                <a-form-item :label="$t('inscription.fraisInscri') + ' (dt)'">
                  <a-input
                    :disabled="true"
                    type="number"
                    step="10"
                    v-decorator="[
                      'fraisInscription',
                      {
                        initialValue: classroom.inscription,
                        rules: [
                          {
                            required: false,
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </div>
              <div class="col-lg-6">
                <a-form-item :label="$t('inscription.fraisMensuel') + ' (dt)'">
                  <a-input
                    type="number"
                    :disabled="true"
                    step="1"
                    :placeholder="$t('inscription.fraisMensuel') + ' (dt)'"
                    v-decorator="[
                      'fraisMensuel',
                      {
                        initialValue: classroom.monthly,
                        rules: [
                          {
                            required: false,
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </div>
            </div>
            <!--<div class="row">
              <div class="col-lg-2">
                <a-form-item
                  :label="
                    $t('inscription.remise') +
                    ' (' +
                    reductionVal.inscription +
                    '%)'
                  "
                >
                  <a-switch
                    :checked="reduction.inscription"
                    @change="
                      () => (reduction.inscription = !reduction.inscription)
                    "
                  />
                </a-form-item>
              </div>
              <div class="col-lg-4">
                <a-form-item :label="$t('inscription.remiseInscri')">
                  <a-input
                    type="number"
                    @change="
                      (e) => (reductionVal.inscription = e.srcElement.value)
                    "
                    step="1"
                    :disabled="!reduction.inscription"
                    :placeholder="$t('inscription.remiseInscri')"
                    v-decorator="[
                      `reduction.inscription`,
                      {
                        initialValue: 0,
                        rules: [
                          {
                            required: reduction.inscription,
                            message: $t('requis.pourcentage'),
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </div>
              <div class="col-lg-2">
                <a-form-item
                  :label="
                    $t('inscription.remise') +
                    ' (' +
                    reductionVal.monthly +
                    '%)'
                  "
                >
                  <a-switch
                    :checked="reduction.monthly"
                    @change="() => (reduction.monthly = !reduction.monthly)"
                  />
                </a-form-item>
              </div>
              <div class="col-lg-4">
                <a-form-item :label="$t('inscription.remiseMensuel')">
                  <a-input
                    @change="(e) => (reductionVal.monthly = e.srcElement.value)"
                    type="number"
                    step="1"
                    :disabled="!reduction.monthly"
                    :placeholder="$t('inscription.remiseMensuel')"
                    v-decorator="[
                      `reduction.monthly`,
                      {
                        initialValue: 0,
                        rules: [
                          {
                            required: reduction.monthly,
                            message: $t('requis.pourcentage'),
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </div>
            </div>-->
            <div class="form-actions mt-0">
              <a-button
                style="width: 150px"
                type="primary"
                htmlType="submit"
                :loading="loadingInscription"
                class="mr-3"
              >
                {{ $t("action.ajouter") }}
              </a-button>
              <a-button
                key="back"
                @click="handleCancel"
                :disabled="loadingInscription"
              >
                {{ $t("action.annuler") }}
              </a-button>
            </div>
          </a-form>
        </a-modal>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import JsPDF from "jspdf";
import "jspdf-autotable";
import $ from "jquery";
import router from "@/router";
import apiClient from "@/services/axios";
import moment from "moment";
import { mapState } from "vuex";
import { PageHeader } from "ant-design-vue";
var init = true;

export default {
  computed: mapState(["settings"]),
  components: {
    "a-page-header": PageHeader,
  },
  props: {
    classId: {
      required: true,
    },
  },
  async created() {
    this.tableLoading = true;
    apiClient
      .post("/classrooms/filter", {
        query: {
          _id: this.classId,
        },
        aggregation: {
          $project: {
            name: 1,
            inscription: 1,
            monthly: 1,
          },
        },
      })
      .then((res) => (this.classroom = res.data[0]))
      .catch(() => {
        this.$message.error("Classe non trouvé!");
        router.go(-1);
      });
    await apiClient
      .post("/classrooms/filter", {
        query: {
          status: "active",
        },
        aggregation: {
          $project: {
            _id: 1,
          },
        },
      })
      .then((res) => {
        this.classrooms = res.data.map((e) => e._id);
      })
      .catch(() => {
        this.$message.error("Classes non trouvé!");
        router.go(-1);
      });
    await apiClient
      .post("/students/filter", {
        query: {
          status: "active",
        },
        aggregation: {
          $project: {
            firstName: 1,
            lastName: 1,
            classRoom: 1,
            schoolarYearsHistory: 1,
          },
        },
      })
      .then((res) => {
        res.data.forEach((elem) => {
          if (
            !elem.classRoom ||
            !elem.schoolarYearsHistory[this.settings.activeSchoolarYear]
          )
            this.eleves.push(elem);
        });
      })
      .catch((e) => {
        this.$message.error("List des élèves non trouvés");
      });

    this.getStudentTable();
  },
  data() {
    this.dateFormat = moment("DD MM-YYYY");
    return {
      tableLoading: false,
      reduction: { inscription: false, monthly: false },
      reductionVal: { inscription: 0, monthly: 0 },
      classroomCount: 0,
      classroom: {},
      columns: [
        {
          title: this.$t("inscription.nom"),
          dataIndex: "fullName",
          key: "fullName",
          scopedSlots: {
            customRender: "fullName",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) =>
            record.fullName
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: this.$t("inscription.dateNaissance"),
          dataIndex: "BirthDate",
          key: "BirthDate",
          scopedSlots: {
            customRender: "BirthDate",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) =>
            record.BirthDate.toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: this.$t("inscription.sexe"),
          dataIndex: "gender",
          key: "gender",
          scopedSlots: {
            customRender: "gender",
          },
          filters: [
            { text: "Garçon", value: "male" },
            { text: "Fille", value: "female" },
          ],
          onFilter: (value, record) => record.gender.indexOf(value) === 0,
        },
        {
          title: this.$t("inscription.tels"),
          dataIndex: "phone",
          key: "phone",
          scopedSlots: {
            customRender: "phone",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) =>
            record.phone.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: this.$t("inscription.parent"),
          dataIndex: "parent",
          key: "parent",
          scopedSlots: {
            customRender: "parent",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) =>
            record.parent
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
      ],
      classrooms: [],
      data: [],
      firstPage: true,
      selectedClass: null,
      changeMois: null,
      defaultValue: null,
      editingKey: "",
      editable: false,
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      loadingInscription: false,
      visibleModal: false,
      filtredTable: [],
      form: this.$form.createForm(this),
      date: null,
      fraisInscription: null,
      fraisMensuel: null,
      eleves: [],
    };
  },
  methods: {
    async getStudentTable() {
      let match = {};
      match[`schoolarYearsHistory.${this.settings.activeSchoolarYear}`] =
        this.classId;
      await apiClient
        .post("/students/filter", {
          query: { status: "active" },
          aggregation: [
            {
              $match: match,
            },
            {
              $lookup: {
                from: "parents",
                localField: "parent",
                foreignField: "_id",
                as: "parent",
              },
            },
            {
              $project: {
                _id: 1,
                firstName: 1,
                lastName: 1,
                parent: {
                  father: 1,
                  mother: 1,
                },
                gender: 1,
                BirthDate: 1,
                name: 1,
              },
            },
          ],
        })
        .then((res) => {
          this.classroomCount = res.data.length;
          this.data = res.data.map((elem) => ({
            key: elem._id,
            fullName: elem.firstName + " " + elem.lastName,
            parent:
              elem.parent[0].father.firstName +
              " " +
              elem.parent[0].father.lastName +
              "/" +
              elem.parent[0].mother.firstName +
              " " +
              elem.parent[0].mother.lastName,
            BirthDate: moment(elem.BirthDate).format("DD/MM/YYYY"),
            gender: elem.gender,
            phone:
              elem.parent[0].father.phone + "/" + elem.parent[0].mother.phone,
          }));
          this.filtredTable = this.data.map((item) => ({ ...item }));

          this.cacheData = this.data.map((item) => ({ ...item }));
        });

      this.tableLoading = false;
      //this.filtredTable = this.activeData;
    },
    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
    },
    test(any) {},
    moment,
    onChangeCheckBox(e) {},
    handleChangeEleve(value) {
      this.eleve = value;
    },
    showModal() {
      this.visibleModal = true;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    addInscription(e) {
      this.loadingInscription = true;
      e.preventDefault();
      console.log(this.form);
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          const activeSchoolarYear = this.settings.activeSchoolarYear;
          const schoolarYears = this.settings.schoolarYears;
          let newInscription = { ...values };
          schoolarYears.forEach((elem) => {
            if (elem._id == activeSchoolarYear) {
              newInscription.end = elem.end;
              if (!values.start) newInscription.start = elem.start;
            }
          });
          delete newInscription.fraisMensuel;
          delete newInscription.fraisInscription;

          apiClient
            .put(
              `/classrooms/${this.classId}/studentsRegestration/`,
              newInscription
            )
            .then(async (res) => {
              await this.getStudentTable();
              this.handleCancel();
              this.eleves = this.eleves.filter(
                (elem) => !values.students.includes(elem._id)
              );
              this.$message.success(this.$t("success.inscriAjout"));
            })
            .catch((e) => {
              this.$message.error(this.$t("error.inscriEchoue"));
              console.log(e);
            })
            .finally(() => {
              this.loadingInscription = false;
            });
        } else {
          this.loadingInscription = false;
        }
      });
    },
    handleCancel() {
      this.form.resetFields();
      this.reductionVal = { inscription: 0, monthly: 0 };
      this.reduction = { inscription: false, monthly: false };
      this.visibleModal = false;
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    edit(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = key;
      if (target) {
        target.editable = true;
        this.data = newData;
      }
    },
    save(key) {
      const newData = [...this.data];
      const newCacheData = [...this.cacheData];
      const target = newData.filter((item) => key === item.key)[0];
      const targetCache = newCacheData.filter((item) => key === item.key)[0];
      if (target && targetCache) {
        delete target.editable;
        this.data = newData;
        Object.assign(targetCache, target);
        this.cacheData = newCacheData;
      }
      this.editingKey = "";
    },
    cancel(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = "";
      if (target) {
        Object.assign(
          target,
          this.cacheData.filter((item) => key === item.key)[0]
        );
        delete target.editable;
        this.data = newData;
      }
    },
    onChangeDate(date, dateString) {
      this.date = dateString;
    },
    selectClass(id) {
      this.firstPage = false;
      this.selectedClass = id;
    },
    handleChangeMois(value) {
      this.changeMois = value;
    },
    disabledDate(current) {
      const activeSchoolarYear = this.settings.activeSchoolarYear;
      const schoolarYears = this.settings.schoolarYears;
      const schoolarYear = schoolarYears.find(
        (elem) => elem._id == activeSchoolarYear
      );
      const { start, end } = schoolarYear;
      return (current && current.isAfter(end)) || current.isBefore(start);
    },
    convert() {
      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      var doc = new JsPDF();
      doc.setFont("Amiri");
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      today = dd + "/" + mm + "/" + yyyy;
      var className = this.classroom.name;
      doc.setFontSize(15);
      doc.text(92, 60, className);
      doc.setFontSize(20);
      doc.text(85, 50, "Fiche class");
      doc.setFontSize(12);
      doc.text(150, 25, "Imprimé le : " + " " + today);
      doc.setFontSize(40);
      doc.addImage(imgData, "JPEG", 10, 10, 40, 40);
      var arr = [];
      const array = [];
      let garcon = 0;
      let fille = 0;
      for (let i = 0; i < this.filtredTable.length; i++)
        this.filtredTable[i].gender === "male" ? garcon++ : fille++;
      arr.push({
        rowSpan: garcon,
        content: "Garçon",
        styles: { valign: "middle" },
      });
      for (let i = 0; i < this.filtredTable.length; i++) {
        if (this.filtredTable[i].gender === "male") {
          arr.push(this.filtredTable[i].fullName);
          array.push(arr);
          arr = [];
        }
      }
      arr.push({
        rowSpan: fille,
        content: "Fille",
        styles: { valign: "middle" },
      });
      for (let i = 0; i < this.filtredTable.length; i++) {
        if (this.filtredTable[i].gender === "female") {
          arr.push(this.filtredTable[i].fullName);
          array.push(arr);
          arr = [];
        }
      }
      doc.autoTable({
        theme: "grid",
        styles: { font: "Amiri" },
        head: [["Sexe", "Nom & Prénom"]],
        body: array,
        margin: { top: 70 },
        foot: [["Total", this.filtredTable.length]],
      });
      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8); //Optional

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }
      //end page numbering
      doc.save("Fiche_Classe " + className + ".pdf");
    },
    generateFicheClassPDF() {
      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      doc.setFont("Amiri");
      var date = new Date();
      var titre = "Dossier médicale";
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      var className = this.classroom.name;
      doc.setFontSize(15);
      doc.text(89, 60, className);
      doc.setFontSize(20);
      doc.text(85, 50, "Fiche parent");
      doc.setFontSize(40);
      doc.addImage(imgData, "JPEG", 10, 10, 40, 40);
      doc.setFontSize(12);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      var arr = [];
      let counter = 1;
      const array = [];
      for (let i = 0; i < this.filtredTable.length; i++) {
        arr.push(counter);
        arr.push(this.filtredTable[i].fullName);
        arr.push(this.filtredTable[i].BirthDate);
        arr.push(this.filtredTable[i].parent);
        arr.push(this.filtredTable[i].phone);
        array.push(arr);
        arr = [];
        counter++;
      }
      doc.autoTable({
        theme: "grid",
        styles: { font: "Amiri" },
        head: [
          ["Num", "Nom complet", "Date de naissance", "Parent", "Téléphone"],
        ],
        body: array,
        margin: { top: 70 },
      });
      doc.save("Fiche_Classe_parent.pdf");
    },
  },
};
</script>
